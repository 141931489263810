export enum FeatureType {
  /* -------------------------------- Canvas V4 -------------------------------- */
  CanvasV4 = 'feature-canvas-v4',

  /* --------------------------------- Canvas --------------------------------- */
  CanvasThreads = 'feature-canvas-threads',
  CanvasHeatmap = 'feature-canvas-heatmap',
  CanvasRoiStats = 'feature-canvas-roi_stats',
  CanvasUndoRedo = 'feature-canvas-undo_redo',
  CanvasCopyPaste = 'feature-canvas-copy_paste',
  CanvasRabbitMenu = 'feature-canvas-rabbit_menu',
  CanvasRoiStatsV2 = 'feature-canvas-roi_stats_v2',
  CanvasContextMenu = 'feature-canvas-context_menu',
  CanvasOverviewMap = 'feature-canvas-overview_map',
  CanvasMovementStick = 'feature-canvas-movement_stick',
  CanvasLocateAnnotation = 'feature-canvas-locate_annotation',
  CanvasAdaptiveZoomMobile = 'feature-canvas-adaptive_zoom_mobile',
  CanvasAdaptiveZoomTablet = 'feature-canvas-adaptive_zoom_tablet',
  CanvasAdaptiveZoomDesktop = 'feature-canvas-adaptive_zoom_desktop',
  CanvasToolbarAutoAnnotate = 'feature-canvas-toolbar-auto_annotate',
  CanvasQuickActionsMenu = 'feature-canvas-quick_actions_menu',

  /* -------------------------------- Sections -------------------------------- */
  SectionProjectAiActions = 'feature-section-project_ai_actions',

  /* -------------------------------- Projects -------------------------------- */
  ProjectDuplicateAction = 'feature-project-duplicate_action',
  ProjectAutoAnnotateAction = 'feature-project-auto_annotate_action',
  ProjectStatsTableExportCSV = 'feature-project-stats_table_export_csv',

  /* -------------------------------- App -------------------------------- */
  AppDarkMode = 'feature-app-dark_mode',
  AppNotificationForAllWriteRequests = 'feature-app-notification_for_all_write_requests',
}
