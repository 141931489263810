import { DeviceType, detectDeviceType } from 'src/utils/user-agent';

import { GridTemplateConfig } from './types';

// ----------------------------------------------------------------------

const deviceType = detectDeviceType();

// ----------------------------------------------------------------------
export const HEADER = {
  H_MOBILE: 64,
  H_DESKTOP: 80,
  H_DESKTOP_OFFSET: 80 - 16,
};

export const NAV = {
  W_VERTICAL: 280,
  W_MINI: 88,
};

// ----------------------------------------------------------------------

export const FOLDER_GRID_TEMPLATE_COLUMNS: GridTemplateConfig[] = [
  {
    xs: { columns: 'repeat(1, 1fr)', length: 1 },
    sm: { columns: 'repeat(2, 1fr)', length: 2 },
    md: { columns: 'repeat(4, 1fr)', length: 4 },
    lg: { columns: 'repeat(6, 1fr)', length: 6 },
    xl: { columns: 'repeat(6, 1fr)', length: 6 },
  },
];

// ----------------------------------------------------------------------

export const CANVAS_TOP_PADDING = 32;

export const CANVAS_LEFT_PANEL = {
  W: 50,
  R_PADDING: 10,
};

export const CANVAS_RIGHT_PANEL = {
  R_OFFSET: 0,
  T_OFFSET: 0,
  L_PADDING: 10,
  W_EXPANDED: deviceType === DeviceType.Desktop ? 400 : 250,
  W_COLLAPSED: 37.5,
};

export const CANVAS_BOTTOM_PANEL = {
  B_OFFSET: 0,
  H_COLLAPSED: 0,
  H_EXPANDED: 240,
  H_MIN: 240,
  H_MAX: 400,
  L_OFFSET: CANVAS_LEFT_PANEL.W,
  T_PADDING: 16,
};

export const CANVAS_SANDBOX = {
  T_OFFSET: CANVAS_TOP_PADDING,
  R_OFFSET: CANVAS_RIGHT_PANEL.L_PADDING,
  R_OFFSET_COLLAPSED: CANVAS_RIGHT_PANEL.W_COLLAPSED + CANVAS_RIGHT_PANEL.L_PADDING,
  R_OFFSET_EXPANDED: CANVAS_RIGHT_PANEL.W_EXPANDED + CANVAS_RIGHT_PANEL.L_PADDING,
};

export const CANVAS_OVERVIEW_MAP = {
  W: 150,
  H: 150,
  T_OFFSET: CANVAS_TOP_PADDING,
  R_OFFSET: 2 * CANVAS_RIGHT_PANEL.L_PADDING,
  R_OFFSET_COLLAPSED: CANVAS_RIGHT_PANEL.W_COLLAPSED + 2 * CANVAS_RIGHT_PANEL.L_PADDING,
  R_OFFSET_EXPANDED: CANVAS_RIGHT_PANEL.W_EXPANDED + 2 * CANVAS_RIGHT_PANEL.L_PADDING,
};

export const CANVAS_ROI_STATS_CARD = {
  W: { xs: '18rem', md: '45rem' },
  H: { xs: '24rem', md: '31.25rem' },
  R_OFFSET: CANVAS_RIGHT_PANEL.L_PADDING,
  R_OFFSET_EXPANDED: CANVAS_RIGHT_PANEL.W_EXPANDED + CANVAS_RIGHT_PANEL.L_PADDING,
  R_OFFSET_COLLAPSED: CANVAS_RIGHT_PANEL.W_COLLAPSED + CANVAS_RIGHT_PANEL.L_PADDING,
  B_OFFSET_EXPANDED: CANVAS_BOTTOM_PANEL.H_MIN + CANVAS_BOTTOM_PANEL.T_PADDING,
  B_OFFSET_COLLAPSED: CANVAS_BOTTOM_PANEL.H_COLLAPSED + CANVAS_BOTTOM_PANEL.T_PADDING,
};

export const CANVAS_MOVEMENT_CONTROL = {
  W: 120,
  H: 120,
  B_OFFSET_EXPANDED: CANVAS_BOTTOM_PANEL.H_MIN + 50,
  B_OFFSET_COLLAPSED: CANVAS_BOTTOM_PANEL.H_COLLAPSED + 50,
  L_OFFSET: (CANVAS_LEFT_PANEL.W + CANVAS_LEFT_PANEL.R_PADDING) * 2,
};

export const CANVAS_TOOLBAR_BRUSH_CONTROL = {
  T_OFFSET: 190,
  L_OFFSET: CANVAS_LEFT_PANEL.W + CANVAS_LEFT_PANEL.R_PADDING,
};

export const CANVAS_ZOOM_CONTROL = {
  L_OFFSET: CANVAS_LEFT_PANEL.W + CANVAS_LEFT_PANEL.R_PADDING,
  B_OFFSET_EXPANDED: CANVAS_BOTTOM_PANEL.H_MIN + 50,
  B_OFFSET_COLLAPSED: CANVAS_BOTTOM_PANEL.H_COLLAPSED + 50,
};

export const CANVAS_ALERT = {
  W_MIN: '35%',
  W_MAX: { xs: '75%', md: '50%' },
};

export const CONTEXT_MENU = {
  W: 200,
};
