import Map from 'ol/Map';
import hotkeys from 'hotkeys-js';
import { Pixel } from 'ol/pixel';
import { debounce } from 'lodash';
import { Geometry } from 'ol/geom';
import { getArea } from 'ol/sphere';
import BaseLayer from 'ol/layer/Base';
import { OverviewMap } from 'ol/control';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import { Extent, extend, createEmpty, getArea as getExtentArea } from 'ol/extent';

import { store } from 'src/store';
import { EventHub, EventTypes } from 'src/services/event-hub';
import { featCanvasCopyPaste } from 'src/configs/feature-flags';
import { LONG_PRESS_DURATION } from 'src/configs/config-canvas';
import { ThreadsManager } from 'src/services/canvas/canvas-threads';
import ModeManager from 'src/services/canvas/canvas-tools/mode-manager';
import { AmplitudeEvents, AmplitudeService } from 'src/services/amplitude';
import { setCanvasLayoutExpandability } from 'src/store/slices/slice-page-canvas';
import { DEFAULT_MODE } from 'src/services/canvas/canvas-tools/config-canvas-tools';
import {
  Brush,
  DrawType,
  SelectType,
  AnnotationStyle,
} from 'src/services/canvas/types/types-canvas';
import {
  setIsInitialized,
  updateTotalAnnotations,
  setQuickActionsMenuProps,
} from 'src/store/slices/slice-canvas';

import { IDeepZoomImage } from 'src/types/model/dzi';
import { ITissue, TissueType, UNIQUE_TISSUE_TYPES } from 'src/types/types-tissue';

import SandboxManager from './sandbox-manager';
import AnnotationClass from './annotation-class';
import MapBrushManager from './map-brush-manager';
import AnnotationHistory from './annotation-history';
import canvasEmitter from './helpers/canvas-emitter';
import MapShortcutsManager from './map-shortcuts-manager';
import AnnotationClassStoreManager from './annotation-class-store-manager';
import {
  initializeMap,
  featureFilter,
  createDziLayer,
  getCurrentViewArea,
  isAdaptiveZoomEnabled,
} from './helpers/helpers';

// ----------------------------------------------------------------------

export default class MapService {
  private static instance: MapService;

  public map?: Map;

  public overviewMapControl?: OverviewMap;

  public nextAnnotationId: number = 1;

  public extent?: Extent;

  public annotationClasses: AnnotationClass[] = [];

  private _totalAnnotations: number = 0;

  public _activeAnnotationClassId: number = -1;

  public historyManager: AnnotationHistory = new AnnotationHistory();

  public brushManager: MapBrushManager | null = null;

  private minZoom: number = 0;

  public sandboxManager: SandboxManager | null = null;

  public threadsManager: ThreadsManager | null = null;

  public shortcutsManager: MapShortcutsManager | null = null;

  private mousePosition: { x: number; y: number } = { x: 200, y: 200 };

  private mapElement: HTMLElement | null = null;

  private pressTimeout: NodeJS.Timeout | null = null;

  private isLongPressLocked: boolean = false;

  private touchTimeout: NodeJS.Timeout | null = null;

  private touchStartPosition: { x: number; y: number } | null = null;

  private readonly TOUCH_MOVE_THRESHOLD = 10; // pixels

  private constructor() {
    //
  }

  set zoom(zoom: number) {
    this.map?.getView()?.setZoom(zoom);
  }

  get zoom(): number {
    return this.map?.getView()?.getZoom() ?? 0;
  }

  set updateTotalAnnotationsValueBy(value: number) {
    this._totalAnnotations += value;
    store.dispatch(updateTotalAnnotations(this._totalAnnotations));
  }

  get totalAnnotations(): number {
    return this._totalAnnotations;
  }

  private resetTotalAnnotations(): void {
    this.updateTotalAnnotationsValueBy = -1 * this._totalAnnotations;
  }

  public static getInstance(): MapService {
    if (!MapService.instance) {
      MapService.instance = new MapService();
    }

    return MapService.instance;
  }

  public initMap(target: string | HTMLElement, dzi: IDeepZoomImage) {
    // cleanup
    if (this.map) {
      this.annotationClasses.forEach((item) => this.map?.removeLayer(item.layer));
      this.annotationClasses = [];
      this.map.dispose();
      this.historyManager.clearStacks();
    }

    const { layer } = createDziLayer(dzi);
    const { layer: overviewMapLayer } = createDziLayer(dzi);
    const { map, extent, overviewMapControl } = initializeMap(target, layer, overviewMapLayer);
    this.map = map;
    this.extent = extent;
    this.overviewMapControl = overviewMapControl;

    if (overviewMapControl) {
      const overviewMap = overviewMapControl.getOverviewMap();
      overviewMap?.on('click', this.handleOverviewMapClick.bind(this));
    }

    this.calcMinZoom();

    this.brushManager = new MapBrushManager(this.map);
    this.brushManager.addBrushListeners();
    this.threadsManager = new ThreadsManager(this.map, this.extent);
    this.shortcutsManager = new MapShortcutsManager(this.map);

    if (typeof target === 'string') {
      this.mapElement = document.getElementById(target);
    } else {
      this.mapElement = target;
    }

    this.addListeners();

    store.dispatch(setIsInitialized(true));
  }

  // ----------------------------------------------------------------------

  public setClassesVisibility(visible: boolean) {
    this.annotationClasses.forEach((i) => {
      i.visibility = visible;
    });
  }

  public initAnnotationClasses(list: ITissue[]) {
    let newActiveClass: AnnotationClass | undefined;
    const currentActiveClass = this.getActiveAnnotationClass();

    list.forEach((item) => {
      const created = this.addAnnotationClass(
        item.title,
        item.type,
        { strokeColor: item.color },
        Number(item.id)
      );

      if (created) {
        if (created.title === currentActiveClass?.title) {
          newActiveClass = created;
        } else if (created.type === UNIQUE_TISSUE_TYPES.find((i) => i === TissueType.ROI)) {
          newActiveClass = created;
          this.sandboxManager?.setAnnotationClass(created);
        }
      }
    });

    if (newActiveClass) {
      this.setActiveAnnotationClass(newActiveClass.uuid);
      EventHub.emit(EventTypes.TOOLBAR_SELECT_TOOL, { tool: DEFAULT_MODE });
    }
  }

  public addAnnotationClass(
    title: string,
    type: TissueType,
    style?: Partial<AnnotationStyle>,
    id?: number // to specify whether the class created from the BE (then it has an id) or from the FE (then it doesn't has an id, but it has an uuid which will be generated).
  ) {
    if (!this.map) return null;

    const idx = this.annotationClasses.length + 1;
    const created = new AnnotationClass(
      this.map,
      id,
      this.nextAnnotationId,
      title,
      type,
      this.historyManager,
      idx,
      this.extent,
      { ...style }
    );

    this.annotationClasses = [...this.annotationClasses, created];

    this.setActiveAnnotationClass(created.uuid);

    this._activeAnnotationClassId = this.annotationClasses.length - 1;

    AnnotationClassStoreManager.updateStore({
      type: 'TOGGLE_TOTAL_ANNOT_VISIBILITY',
      payload: true,
    });

    this.nextAnnotationId += 1;

    canvasEmitter.emit('classes-updated', { annotationClasses: this.annotationClasses });

    return created;
  }

  public addNewLayer(newLayer: BaseLayer) {
    this.map?.addLayer(newLayer);
  }

  public updateAnnotationClass(id: number, payload: { title?: string; style?: AnnotationStyle }) {
    const updateIdx = this.annotationClasses.findIndex(
      (annotationClass) => annotationClass.uuid === id
    );

    if (updateIdx < 0) return;

    const annotationClass = this.annotationClasses[updateIdx];
    annotationClass.updateProperties(payload.title, payload.style);
    canvasEmitter.emit('classes-updated', { annotationClasses: this.annotationClasses });
  }

  public removeAnnotationClass(id: number) {
    const removedIdx = this.annotationClasses.findIndex(
      (annotationClass) => annotationClass.uuid === id
    );

    if (removedIdx < 0) return;

    const item = this.annotationClasses[removedIdx];
    this.map?.removeLayer(item.layer);
    const originalArray = this.annotationClasses;

    const newArray = originalArray.slice(0, removedIdx).concat(originalArray.slice(removedIdx + 1));
    this.annotationClasses = newArray;

    canvasEmitter.emit('classes-updated', { annotationClasses: this.annotationClasses });
  }

  public getActiveAnnotationClass() {
    if (this._activeAnnotationClassId < 0) return undefined;

    return this.annotationClasses?.[this._activeAnnotationClassId];
  }

  public async deleteAllClassesAnnotations() {
    // Collect all promises from the async operations
    const deletionPromises = this.annotationClasses
      .filter((i) => i.annotations.length > 0)
      .map((i) => i.interactor.bulkDeleteAllAnnotations());

    // Wait for all promises to be fulfilled
    await Promise.all(deletionPromises);

    canvasEmitter.emit('classes-updated', { annotationClasses: this.annotationClasses });
  }

  public hideAllClassesHeatmaps() {
    this.annotationClasses.forEach((annotClass) => {
      annotClass.heatmapManager.setHeatmapVisibility(false);
    });
  }

  public setActiveAnnotationClass(uuid?: number, skipSelectAnnotations = false) {
    if (this.getActiveAnnotationClass()?.uuid === uuid) {
      return;
    }

    let drawType: DrawType | null = null;
    let modifyEnabled = false;
    let translateEnabled = false;
    let selectingEnabled = false;
    const currentMode = ModeManager.getInstance().getMode();

    this.annotationClasses.forEach((element) => {
      drawType = element.interactor.getDrawType() ?? drawType;
      modifyEnabled = element.interactor.isModifyingEnabled() || modifyEnabled;
      translateEnabled = element.interactor.isTranslateEnabled() || translateEnabled;
      selectingEnabled = element.interactor.isSelectingEnabled() || selectingEnabled;

      element.interactor.setDrawType(null);
      if (!skipSelectAnnotations) {
        element.interactor.deselectAllAnnotations();
      }
      // element.disableSelecting();
    });

    this._activeAnnotationClassId = uuid
      ? this.annotationClasses.findIndex((annotationClass) => annotationClass.uuid === uuid)
      : -1;

    const activeClass = this.annotationClasses[this._activeAnnotationClassId];

    canvasEmitter.emit('active-class', { activeClass });

    if (!activeClass) return;

    activeClass.visibility = true;

    if (modifyEnabled) activeClass.interactor.enableModifying();

    if (translateEnabled) activeClass.interactor.enableTranslate();

    if (
      selectingEnabled &&
      [SelectType.Single, SelectType.Box, SelectType.Polygon].includes(currentMode as SelectType)
    ) {
      activeClass.interactor.enableSelecting(currentMode as SelectType);
    }
    if (drawType) activeClass.interactor.setDrawType(drawType);

    if (!skipSelectAnnotations) {
      activeClass.interactor.selectAllAnnotations();
    }
  }

  public locateAnnotationClass(annotClass: AnnotationClass) {
    const source = annotClass.layer.getSource();
    if (!source || !this.map) return;

    const extent = createEmpty();

    if (!annotClass.annotations.length) {
      return;
    }

    annotClass.annotations.forEach((annotation) => {
      extend(extent, annotation.feature?.getGeometry()?.getExtent() ?? []);
    });

    this.map.getView().fit(extent, { duration: 1000, padding: [50, 50, 50, 50] });
  }

  public triggerQuickActionsMenu() {
    if (!this.map) return;

    AnnotationClassStoreManager.updateStore({
      type: 'SET_QUICK_ACTIONS_MENU_PROPS',
      payload: {
        x: this.mousePosition.x,
        y: this.mousePosition.y,
        visible: !store.getState().canvas.quickActionsMenuProps.visible,
      },
    });
  }

  // ----------------------------------------------------------------------

  private addListeners() {
    if (!this.map || !this.extent) return;

    EventHub.on(EventTypes.VIEWER_INIT_ZOOM, ({ zoom }) => {
      this.zoom = zoom;
    });

    EventHub.on(EventTypes.VIEWER_CHANGE_ZOOM, ({ zoom }) => {
      this.zoom = zoom;
    });

    this.map.on('moveend', this.debouncedHandleMoveEndListener());

    this.map.on('moveend', this.handleZoomChanged.bind(this));

    this.map.on('contextmenu' as 'click', this.handleContextMenuClick.bind(this));

    this.map.on('click', this.handleMapClick.bind(this));

    this.map.on('dblclick', this.handleMapDblClick.bind(this));

    hotkeys('del,delete,⌦,⌫', this.handleDelPressed.bind(this));

    hotkeys('esc,escape', this.handleEscPressed.bind(this));

    // this.map.on('pointerdown' as 'pointermove', this.handlePointerDownForQuickActions.bind(this));
    // this.map.on('pointerup' as 'pointermove', this.handlePointerUpForQuickActions.bind(this));

    this.map.on('pointermove', this.handlePointerMove.bind(this));

    this.map.on('movestart', () => {
      const { zoom } = this;
      const minMouseZoomSensitivity = 0.0001;
      if (
        zoom &&
        zoom >= this.minZoom + minMouseZoomSensitivity &&
        store.getState().pageCanvas.canvasLayout.imagesCarousel.isExpanded
      ) {
        store.dispatch(
          setCanvasLayoutExpandability({ section: 'imagesCarousel', isExpanded: false })
        );
      }
    });

    if (this.mapElement) {
      this.mapElement.addEventListener('mousemove', this.handleMouseMove.bind(this));
      this.mapElement.addEventListener('touchstart', this.handleTouchStart.bind(this));
      this.mapElement.addEventListener('touchmove', this.handleTouchMove.bind(this));
      this.mapElement.addEventListener('touchend', this.handleTouchEnd.bind(this));
    }
  }

  private calcMinZoom() {
    const fullExtent = this.map?.getView().calculateExtent(this.map?.getSize());
    const size = this.map?.getSize();
    const resolution = this.map?.getView()?.getResolutionForExtent(fullExtent || [], size)!;
    this.minZoom = this.map?.getView()?.getZoomForResolution(resolution) ?? 0;
  }

  private pasteCount() {
    return this.annotationClasses.reduce((prev, cls) => prev + cls.copier.getCount(), 0);
  }

  private handleContextMenuClick(evt: MapBrowserEvent<UIEvent>) {
    evt.preventDefault();

    if (!this.map) return;

    const mapArea = getCurrentViewArea(this.map);

    const features = this.map
      ?.getFeaturesAtPixel(evt.pixel)
      .filter((f) => featureFilter(f, this.map as Map, mapArea));

    if (!features?.length) {
      if (featCanvasCopyPaste.isEnabled && this.pasteCount()) {
        EventHub.emit(EventTypes.VIEWER_OPEN_CONTEXT_MENU, {
          visible: true,
          position: { x: evt.pixel[0], y: evt.pixel[1] },
        });
      }

      return;
    }

    const smallestFeature = features.sort(
      (f1, f2) => getArea(f1.getGeometry() as Geometry) - getArea(f2.getGeometry() as Geometry)
    )[0];

    const annotClass = this.annotationClasses.find(
      (cls) => cls.uuid === smallestFeature.get('class_uuid') // TODO: manage properties (setter and getter with types)
    );

    const annot = annotClass?.annotations?.find(
      (a) => a.feature.get('id') === smallestFeature.get('id') // TODO: manage properties (setter and getter with types)
    );

    if (annotClass && annot && !annot.selected) {
      this.unselectAllAnnotations();
      this.setActiveAnnotationClass(annotClass.uuid, true);
      annotClass.interactor.selectAnnotation(annot, true, true);
    }

    EventHub.emit(EventTypes.VIEWER_OPEN_CONTEXT_MENU, {
      visible: true,
      position: { x: evt.pixel[0], y: evt.pixel[1] },
    });
  }

  /**
   * Handles the double-click event on the map, selecting the smallest feature
   * if it is not locked or hidden and belongs to a valid annotation class.
   *
   * @param evt - The event object triggered by a double-click on the map.
   *              It contains the pixel location where the click occurred.
   *
   * This method:
   * 1. Filters the features under the double-click position to those that:
   *    - Have a 'class_uuid'.
   *    - Are not locked.
   *    - Are not hidden.
   *
   * 2. If no features are found, it will deselect all annotations in all annotation classes.
   *
   * 3. If features are found, it sorts them by their area size, selects the smallest feature,
   *    and then finds the annotation class that it belongs to.
   *
   * 4. Once the feature's class is found, it selects the corresponding annotation within the class.
   *
   * @returns void
   */
  private handleMapDblClick(evt: MapBrowserEvent<UIEvent>) {
    if (!this.map) return;

    const features = this.getFeaturesAtPixel(evt.pixel);

    if (!features?.length) {
      this.unselectAllAnnotations();
      return;
    }

    const smallestFeature = features[0];

    const annotClass = this.annotationClasses.find(
      (cls) => cls.uuid === smallestFeature.get('class_uuid') // TODO: manage properties (setter and getter with types)
    );

    const annot = annotClass?.annotations?.find(
      (a) => a.feature.get('id') === smallestFeature.get('id') // TODO: manage properties (setter and getter with types)
    );

    if (annotClass && annot && !annot.selected) {
      EventHub.emit(EventTypes.TOOLBAR_SELECT_TOOL, { tool: SelectType.Single });
      this.getActiveAnnotationClass()?.interactor.enableSelecting(SelectType.Single);

      annotClass.interactor.selectAnnotation(annot, true, true);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private hideQuickActionsMenu() {
    if (this.isLongPressLocked) return false;

    if (store.getState().canvas.quickActionsMenuProps.visible) {
      store.dispatch(setQuickActionsMenuProps({ visible: false }));
      return true;
    }

    return false;
  }

  private handleMapClick(evt: MapBrowserEvent<UIEvent>) {
    if (this.hideQuickActionsMenu()) {
      return;
    }

    const currentMode = ModeManager.getInstance().getMode();

    if (currentMode === SelectType.Box || currentMode === SelectType.Polygon) {
      this.unselectAllAnnotations();
      return;
    }

    if (currentMode !== SelectType.Single && (currentMode as any) !== SelectType.Translate) {
      return;
    }

    const features = this.getFeaturesAtPixel(evt.pixel);

    const multi =
      hotkeys.isPressed('command') || hotkeys.isPressed('ctrl') || hotkeys.isPressed('shift');

    if (!features?.length) {
      if (!multi) {
        this.unselectAllAnnotations();
      }

      return;
    }

    const smallestFeature = features.sort((f1, f2) => f1.get('area') - f2.get('area'))[0];

    evt.preventDefault();

    if (!multi) {
      this.unselectAllAnnotations();
    }

    const tissue = this.annotationClasses.find(
      (cls) => cls.uuid === smallestFeature.get('class_uuid')
    );
    const annotation = tissue?.annotations.find(
      (a) => a.feature.get('id') === smallestFeature.get('id')
    );

    if (tissue && annotation) {
      tissue.interactor.selectAnnotation(annotation, false, true);
      AmplitudeService.track(AmplitudeEvents.CanvasAnnotationInteraction, {
        action: 'click',
        'selected-tool': ModeManager.getInstance().getMode(),
      });
    }
  }

  private handleMouseMove(event: MouseEvent) {
    if (!this.mapElement) return;

    const rect = this.mapElement?.getBoundingClientRect(); // Get the size and position of the div
    const x = event.clientX - rect.left; // X-coordinate relative to the div
    const y = event.clientY - rect.top; // Y-coordinate relative to the div

    this.mousePosition = { x, y };
  }

  private getFeaturesAtPixel(pixel: Pixel) {
    if (!this.map) return [];

    const mapArea = getCurrentViewArea(this.map);

    return this.map
      .getFeaturesAtPixel(pixel)
      .filter((f) => featureFilter(f, this.map as Map, mapArea))
      .sort((f1, f2) => f1.get('area') - f2.get('area'));
  }

  private handleDelPressed(event: any) {
    event.preventDefault();

    AmplitudeService.track(AmplitudeEvents.CanvasShortcutPressed, {
      'shortcut-key': 'del',
      'shortcut-action': 'delete',
      'selected-tool': ModeManager.getInstance().getMode(),
    });

    this.annotationClasses.forEach((annotClass) => {
      annotClass.interactor.handleDelClicked();
    });
  }

  private handlePointerMove(event: MapBrowserEvent<any>) {
    if (!this.extent || !this.map) return;

    const [minX, minY, maxX, maxY] = this.extent;

    // To prevent mouse interactions in the map when the cursor is outside the core content (the real extent)
    const [x, y] = this.map.getEventCoordinate(event.originalEvent);

    // Check if the mouse is within the real extent
    const isInside = x >= minX && x <= maxX && y >= minY && y <= maxY;

    if (!isInside && ModeManager.getInstance().getMode() !== SelectType.Single) {
      // Prevent default interaction (e.g., pan, zoom)
      event.stopPropagation();
      event.preventDefault();
      this.map.getViewport().style.cursor = 'not-allowed'; // Change cursor style
    } else {
      this.map.getViewport().style.cursor = ''; // Reset cursor style when inside the core content
    }
  }

  private handleEscPressed(event: any) {
    event.preventDefault();

    if (this.hideQuickActionsMenu()) {
      return;
    }

    this.annotationClasses.forEach((annotClass) => {
      annotClass.interactor.handleEscClicked();
    });
  }

  public unselectAllAnnotations() {
    this.annotationClasses.forEach((cls) => {
      cls.interactor.deselectAllAnnotations();
    });
  }

  public disableAllInteractions() {
    this.annotationClasses.forEach((annotClass) => annotClass.interactor.disableAllInteractions());
  }

  // ----------------------------------------------------------------------

  public reset() {
    this.annotationClasses.forEach((annotClass) => {
      annotClass.dispose();
      this.map?.removeLayer(annotClass.layer);
    });

    this.annotationClasses = [];
    this.resetTotalAnnotations();
    store.dispatch(setIsInitialized(false));
    this.historyManager.clearStacks();
    this.shortcutsManager?.removeListeners();
    this.brushManager?.reset();

    if (this.overviewMapControl) {
      this.overviewMapControl?.getMap()?.removeEventListener('click', this.handleOverviewMapClick);
    }

    EventHub.emit(EventTypes.CANVAS_CLEANUP, undefined);
  }

  // ----------------------------------------------------------------------

  private debouncedHandleMoveEndListener() {
    return debounce(this.handleMoveEndListener.bind(this), 300, { maxWait: 1000 });
  }

  private handleOverviewMapClick(event: any) {
    const { pixel } = event;
    const coordinate = this.overviewMapControl?.getOverviewMap()?.getCoordinateFromPixel(pixel);
    this.map?.getView().setCenter(coordinate);
  }

  public handleMoveEndListener() {
    const { totalAnnotations } = store.getState().canvas;
    if (!isAdaptiveZoomEnabled(totalAnnotations)) {
      return;
    }

    const view = this.map?.getView();
    const mapExtent = view?.calculateExtent(this.map?.getSize());
    if (!view || !mapExtent) {
      return;
    }

    const mapArea = getExtentArea(mapExtent);
    let totalDrawCount = 0;
    let totalRemoveCount = 0;
    const maxZoomReached = this.zoom === view.getMaxZoom();

    this.annotationClasses.forEach((annotationClass) => {
      const { drawCount, removeCount } = annotationClass.syncAnnotationWithView(
        mapArea,
        mapExtent,
        maxZoomReached
      );
      totalDrawCount += drawCount;
      totalRemoveCount += removeCount;
    });

    console.info(`Annotations synced =>      🟢 ${totalDrawCount}     🔴 ${totalRemoveCount}`);
  }

  private handleZoomChanged() {
    EventHub.emit(EventTypes.VIEWER_ZOOM_CHANGED, { zoom: this.zoom });
    this.brushManager?.updatePreviewBrush();
  }

  private handlePointerDownForQuickActions(event: MapBrowserEvent<any>) {
    const currentMode = ModeManager.getInstance().getMode();

    if (
      currentMode !== Brush.Brush &&
      currentMode !== Brush.BrushDraw &&
      event.originalEvent.pointerType === 'touch' &&
      !this.isLongPressLocked
    ) {
      event.originalEvent.preventDefault();

      this.pressTimeout = setTimeout(() => {
        this.mousePosition = { x: event.pixel[0], y: event.pixel[1] };

        this.triggerQuickActionsMenu();

        this.isLongPressLocked = true;

        setTimeout(() => {
          this.isLongPressLocked = false;
        }, LONG_PRESS_DURATION + 100);
      }, LONG_PRESS_DURATION);
    }
  }

  private handlePointerUpForQuickActions(event: MapBrowserEvent<any>) {
    if (this.pressTimeout && event.originalEvent.pointerType === 'touch') {
      clearTimeout(this.pressTimeout); // Cancel the long press if released early
    }
  }

  private handleTouchStart(event: TouchEvent) {
    if (event.touches.length !== 1) return; // Only handle single touch

    const touch = event.touches[0];
    this.touchStartPosition = { x: touch.clientX, y: touch.clientY };

    this.touchTimeout = setTimeout(() => {
      if (this.touchStartPosition && !this.isLongPressLocked) {
        const rect = this.mapElement?.getBoundingClientRect();
        if (!rect) return;

        // Convert touch coordinates to map pixel coordinates
        const pixel = [touch.clientX - rect.left, touch.clientY - rect.top];

        // Get features at the touch location
        const features = this.getFeaturesAtPixel(pixel);

        if (features.length > 0) {
          const smallestFeature = features[0];

          // Find the annotation class and annotation
          const annotClass = this.annotationClasses.find(
            (cls) => cls.uuid === smallestFeature.get('class_uuid')
          );
          const annotation = annotClass?.annotations.find(
            (a) => a.feature.get('id') === smallestFeature.get('id')
          );

          if (annotClass && annotation) {
            // Switch to single select mode
            console.log('switching to single select mode');
            this.getActiveAnnotationClass()?.interactor.enableSelecting(SelectType.Single);

            // Select the annotation
            annotClass.interactor.selectAnnotation(annotation, true, true);
          }
        }

        this.isLongPressLocked = true;

        setTimeout(() => {
          this.isLongPressLocked = false;
        }, LONG_PRESS_DURATION + 100);
      }
    }, LONG_PRESS_DURATION);
  }

  private handleTouchMove(event: TouchEvent) {
    if (!this.touchStartPosition || !this.touchTimeout) return;

    const touch = event.touches[0];
    const moveX = Math.abs(touch.clientX - this.touchStartPosition.x);
    const moveY = Math.abs(touch.clientY - this.touchStartPosition.y);

    // Cancel long press if finger moves too much
    if (moveX > this.TOUCH_MOVE_THRESHOLD || moveY > this.TOUCH_MOVE_THRESHOLD) {
      clearTimeout(this.touchTimeout);
      this.touchTimeout = null;
      this.touchStartPosition = null;
    }
  }

  private handleTouchEnd() {
    if (this.touchTimeout) {
      clearTimeout(this.touchTimeout);
      this.touchTimeout = null;
    }
    this.touchStartPosition = null;
  }
}
