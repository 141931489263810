import { store } from 'src/store';
import {
  setQuickActionsMenuProps,
  toggleTotalAnnotationsVisibility,
} from 'src/store/slices/slice-canvas';

import { AnnotationClassStoreAction } from './types/types';

// ----------------------------------------------------------------------

/**
 * StoreManager is responsible for managing actions related to annotations,
 * such as setting modes, updating annotations, and tracking changes.
 */
export default class AnnotationClassStoreManager {
  /**
   * A map of action types to their corresponding action creators.
   */
  protected static actionMap: {
    [key in AnnotationClassStoreAction['type']]: (payload: any) => any;
  } = {
    TOGGLE_TOTAL_ANNOT_VISIBILITY: toggleTotalAnnotationsVisibility,
    SET_QUICK_ACTIONS_MENU_PROPS: setQuickActionsMenuProps,
  };

  /**
   * Updates the store based on the specified action and payload.
   * @param action - The action object containing type and payload.
   */
  static updateStore(action: AnnotationClassStoreAction) {
    const actionCreator = AnnotationClassStoreManager.actionMap[action.type];
    store.dispatch(actionCreator(action.payload));
  }
}
