import { m, domMax, LazyMotion } from 'framer-motion';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export function MotionLazy({ children }: Props) {
  return (
    <LazyMotion strict features={domMax}>
      <m.div id='motion-lazy' style={{ height: '100%' }}>
        {children}
      </m.div>
    </LazyMotion>
  );
}
