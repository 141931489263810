import 'ol/ol.css';
import React, { useMemo, useState, ReactNode, useEffect, createContext } from 'react';

import { useAppSelector } from 'src/store/hooks';
import { EventTypes, useSubscribe } from 'src/services/event-hub';
import ModeManager from 'src/services/canvas/canvas-tools/mode-manager';
import { DEFAULT_MODE } from 'src/services/canvas/canvas-tools/config-canvas-tools';

import MapService from '../map-service';
import { SelectType, AnnotationMode } from '../types/types-canvas';

// ----------------------------------------------------------------------

export const MapServiceContext = createContext<{
  mapService: MapService;
  currentMode: AnnotationMode;
} | null>(null);

// ----------------------------------------------------------------------

export const MapServiceProvider: React.FC<any> = ({ children }: { children: ReactNode }) => {
  const [mode, setMode] = useState<AnnotationMode>(DEFAULT_MODE);
  const { isCanvasPredicting } = useAppSelector(({ pageCanvas }) => pageCanvas);

  const mapService = useMemo(() => MapService.getInstance(), []);

  useSubscribe(EventTypes.TOOLBAR_TOOL_SELECTED, ({ tool }) => {
    setMode(tool);
  });

  useEffect(() => {
    if (isCanvasPredicting) {
      ModeManager.getInstance().setMode(SelectType.Single);
      mapService.shortcutsManager?.disableAllShortcuts();
    } else {
      mapService.shortcutsManager?.resetEnabledShortcuts();
    }
  }, [isCanvasPredicting, mapService.shortcutsManager]);

  const memoizedValue = useMemo(
    () => ({
      mapService,
      currentMode: mode,
    }),
    [mapService, mode]
  );

  return <MapServiceContext.Provider value={memoizedValue}>{children}</MapServiceContext.Provider>;
};
