import { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import CanvasLoading from 'src/pages/canvas/loading';
import { PageProvider } from 'src/contexts/page-context';
import { featCanvasV4 } from 'src/configs/feature-flags';
import { PATH_AFTER_LOGIN } from 'src/configs/config-global';
import { Params } from 'src/helpers/mapper-route-placeholder';
import { AuthGuard, RoleBasedGuard } from 'src/services/auth/guard';
import { MapServiceProvider } from 'src/services/canvas/context/map-service-context';

// ----------------------------------------------------------------------

const PageImageCanvas = lazy(() => import('src/pages/canvas/page-image-canvas/image-canvas'));
const PageSketchCanvas = lazy(() => import('src/pages/canvas/page-sketch-canvas/sketch-canvas'));
const PageImageCanvasV4 = lazy(() => import('src/pages/canvas/page-image-canvas-v4'));
const PageSketchCanvasV4 = lazy(() => import('src/pages/canvas/page-sketch-canvas-v4'));

// ----------------------------------------------------------------------

export const canvasRoutes = [
  {
    path: 'canvas',
    element: (
      <AuthGuard>
        <Suspense fallback={<CanvasLoading />}>
          <MapServiceProvider>
            <Outlet />
          </MapServiceProvider>
        </Suspense>
      </AuthGuard>
    ),
    children: [
      { index: true, element: <Navigate to={PATH_AFTER_LOGIN} replace /> },
      {
        path: `ai-projects/:${Params.ProjectId}/snapshots/:${Params.SnapshotId}/images/:${Params.SketchId}`,
        element: (
          <PageProvider>
            <PageSketchCanvas />
          </PageProvider>
        ),
      },
      {
        path: `folders/:${Params.FolderId}/images/:${Params.ImageId}`,
        element: (
          <PageProvider>
            <PageImageCanvas />
          </PageProvider>
        ),
      },
    ],
  },

  /* -------------------------------- Canvas V4 ------------------------------- */
  ...(featCanvasV4.isEnabled
    ? [
        {
          path: 'canvas-v4',
          element: (
            <AuthGuard>
              <RoleBasedGuard roles={featCanvasV4.accessibleRoles}>
                <Suspense fallback={<CanvasLoading />}>
                  <Outlet />
                </Suspense>
              </RoleBasedGuard>
            </AuthGuard>
          ),
          children: [
            { index: true, element: <Navigate to={PATH_AFTER_LOGIN} replace /> },
            {
              path: `folders/:${Params.FolderId}/images/:${Params.ImageId}`,
              element: (
                <PageProvider>
                  <PageImageCanvasV4 />
                </PageProvider>
              ),
            },
            {
              path: `ai-projects/:${Params.ProjectId}/snapshots/:${Params.SnapshotId}/images/:${Params.SketchId}`,
              element: (
                <PageProvider>
                  <PageSketchCanvasV4 />
                </PageProvider>
              ),
            },
          ],
        },
      ]
    : []),
];
