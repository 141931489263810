// ----------------------------------------------------------------------

// Helper functions
const getRandomColorValue = () => Math.floor(Math.random() * 256);

const toHex = (num: number) => num.toString(16).padStart(2, '0').toUpperCase();

const rgbaStringToValues = (rgba: string) => {
  const matches = rgba.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*([\d.]+)?\)/);
  if (!matches) {
    throw new Error('Invalid RGBA color string');
  }

  const r = parseInt(matches[1], 10);
  const g = parseInt(matches[2], 10);
  const b = parseInt(matches[3], 10);
  const a = matches[4] ? parseFloat(matches[4]) : 1;

  return { r, g, b, a };
};

const isHexColor = (color: string) => /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{8})$/.test(color);

function isValidRgbOrRgbaColor(color: string): boolean {
  color = color.toLowerCase();

  const rgbRegex = /^rgb\(\s*(0|[1-9]\d{0,2})\s*,\s*(0|[1-9]\d{0,2})\s*,\s*(0|[1-9]\d{0,2})\s*\)$/;
  const rgbaRegex =
    /^rgba\(\s*(0|[1-9]\d{0,2})\s*,\s*(0|[1-9]\d{0,2})\s*,\s*(0|[1-9]\d{0,2})\s*,\s*(0(\.\d+)?|1(\.0+)?)\s*\)$/;

  return rgbRegex.test(color) || rgbaRegex.test(color);
}

// Utility functions
export function getRandomHexColor() {
  let color = '#';
  for (let i = 0; i < 6; i += 1) {
    color += '0123456789ABCDEF'[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function getRandomRGBAColor(randomAlpha: boolean = true) {
  const r = getRandomColorValue();
  const g = getRandomColorValue();
  const b = getRandomColorValue();
  const a = randomAlpha ? Math.random().toFixed(2) : '1';
  const rgbaString = `rgba(${r}, ${g}, ${b}, ${a})`;

  return rgbaString;
}

export function changeColorOpacity(color: string, opacity: number) {
  try {
    const { r, g, b } = rgbaStringToValues(color);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  } catch (err) {
    console.error(err);
  }
  return color;
}

export function rgbaToHex(rgba: string): string {
  if (isHexColor(rgba) || !isValidRgbOrRgbaColor(rgba)) {
    return rgba;
  }
  try {
    const { r, g, b, a } = rgbaStringToValues(rgba);
    const alpha = Math.round(a * 255);
    const hex = `#${toHex(r)}${toHex(g)}${toHex(b)}${toHex(alpha)}`;
    return hex;
  } catch (err) {
    console.error(err);
  }
  return rgba;
}

export function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
