import { container } from 'src/services/container';
import { TYPES } from 'src/services/enum-services';
import { RequestType, IHttpClient } from 'src/services/http';
import { DynamicActionList } from 'src/services/api/types/types-dynamic-components';

import { endpoints } from './endpoints';
import { ActionType } from './types/enum-actions';

// ----------------------------------------------------------------------

const httpClient = container.get<IHttpClient>(TYPES.HttpClient);

// ----------------------------------------------------------------------

export async function getInit() {
  const args: RequestType = { url: endpoints.init, method: 'GET' };

  type Response = { actions: DynamicActionList<ActionType.Me> };

  const data = await httpClient.request.bind(httpClient)<Response>(args);

  return data;
}

// ----------------------------------------------------------------------
