import { Amplify } from 'aws-amplify';
import {
  signIn,
  signOut,
  getCurrentUser,
  fetchAuthSession,
  fetchUserAttributes,
} from 'aws-amplify/auth';

import { AmplitudeService } from 'src/services/amplitude';
import { EventHub, EventTypes } from 'src/services/event-hub';
import { AmplitudeEvents } from 'src/services/amplitude/enum-events';
import {
  AWS_AMPLIFY_USER_POOL_ID,
  AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
} from 'src/configs/config-services';

import { UserAttributes } from 'src/types/types-user';

import { IAuthService } from './auth.interface';
import { SignUpInput, AuthMethodType } from './types';

// ----------------------------------------------------------------------

export class AmplifyAuthService implements IAuthService {
  readonly type: AuthMethodType = AuthMethodType.Amplify;

  constructor() {
    /**
     * DOCS: https://docs.amplify.aws/react/tools/libraries/configure-categories/#authentication-amazon-cognito
     */
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: AWS_AMPLIFY_USER_POOL_ID,
          userPoolClientId: AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
        },
      },
    });
  }

  signIn: IAuthService['signIn'] = signIn;

  // eslint-disable-next-line class-methods-use-this
  async signUp({ user }: SignUpInput) {
    await AmplitudeService.track(AmplitudeEvents.AuthRegisterAccount, {
      'first-name': user.firstname,
      'last-name': user.lastname,
      'org-name': user.organization,
      'email-address': user.email,
      'use-case-purpose': user.useCasePurpose,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async getCurrentUser() {
    const currentUser = await getCurrentUser();
    return currentUser;
  }

  fetchAuthSession: IAuthService['fetchAuthSession'] = fetchAuthSession;

  // eslint-disable-next-line class-methods-use-this
  async fetchUserAttributes() {
    const userAttributes = (await fetchUserAttributes()) as UserAttributes;
    return userAttributes;
  }

  // eslint-disable-next-line class-methods-use-this
  getTokens: IAuthService['getTokens'] = async () => {
    const { idToken, accessToken } = (await fetchAuthSession())?.tokens ?? {};
    return { idToken, accessToken };
  };

  // eslint-disable-next-line class-methods-use-this
  signOut: IAuthService['signOut'] = async (input) => {
    const { options } = input ?? {};

    if (options && options.emitEvent) {
      EventHub.emit(EventTypes.AUTH_SIGN_OUT, undefined);
      return Promise.resolve();
    }

    await signOut();
    return Promise.resolve();
  };
}
