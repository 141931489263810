import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export function container(theme: Theme) {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {
          '&.section-container': {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4),
            borderRadius: '7px',
          },
        },
      },
    },
  };
}
