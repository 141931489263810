import { Stack, Skeleton } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';

// ----------------------------------------------------------------------

export default function Loading() {
  return (
    <Grid2 component='main' container sx={{ height: '100%', position: 'relative' }}>
      <Grid2 container sx={{ position: 'absolute', top: 0, left: 0, right: 0, height: '100%' }}>
        {/* Left skeleton */}
        <Grid2 sx={{ width: 50, height: '100%', zIndex: 1 }}>
          <Stack sx={{ height: '100%', p: 0, bgcolor: 'background.paper' }}>
            <Skeleton variant='rectangular' height='100%' />
          </Stack>
        </Grid2>

        {/* Middle skeleton */}
        <Grid2 xs display='flex' flexDirection='column' sx={{ height: '100%' }}>
          <Stack
            sx={{ height: '100%', px: 1, py: 2, justifyContent: 'center', alignItems: 'center' }}
          >
            <Skeleton
              variant='rectangular'
              height='75%'
              width='75%'
              animation='wave'
              sx={{
                borderRadius: 1,
                bgcolor: 'background.neutral',
              }}
            />
          </Stack>

          <Stack sx={{ mt: 'auto', zIndex: 1 }}>
            <Skeleton variant='rectangular' height='240px' />
          </Stack>
        </Grid2>

        {/* Right skeleton */}
        <Grid2 sx={{ width: 375, height: '100%', zIndex: 1 }}>
          <Stack sx={{ height: '100%', p: 0, bgcolor: 'background.paper' }}>
            <Skeleton variant='rectangular' height='100%' />
          </Stack>
        </Grid2>
      </Grid2>
    </Grid2>
  );
}
