import { UserRoleType } from 'src/types/types-user';

import { ConfigFeature } from '../helpers/config-feature';

import { FeatureType } from './enum-features';

/* --------------------------------- App ------------------------------ */

export const featAppDarkMode = new ConfigFeature(FeatureType.AppDarkMode, {
  enabled: true,
  accessRoles: [UserRoleType.Admin],
});

export const featAppNotificationForAllWriteRequests = new ConfigFeature(
  FeatureType.AppNotificationForAllWriteRequests,
  { enabled: false }
);
