import axios from 'axios';
import { injectable } from 'inversify';
import type {
  AxiosError,
  AxiosInstance,
  CreateAxiosDefaults,
  InternalAxiosRequestConfig,
} from 'axios';

import { paths } from 'src/routes/paths';
import History from 'src/routes/history';

import type { IAuthService } from 'src/services/auth';
import { LocalStorage } from 'src/helpers/local-storage';
import { type ILoggerService } from 'src/services/logger';
import { StorageKeysType } from 'src/configs/types/enum-storage-keys';
import { type INotificationService } from 'src/services/notification';
import { featAppNotificationForAllWriteRequests } from 'src/configs/feature-flags';

import { IHttpClient } from './http.interface';
import { RequestType, ResponseType } from './types';

// ----------------------------------------------------------------------

@injectable()
export class AxiosHttpClient implements IHttpClient {
  private instance: AxiosInstance;

  constructor(
    config: CreateAxiosDefaults,
    private authService: IAuthService,
    private loggerService: ILoggerService,
    private notificationService: INotificationService
  ) {
    this.instance = axios.create(config);

    this.notificationService = notificationService;

    this.loggerService = loggerService;

    this.authService = authService;

    this.setupInterceptors();
  }

  private async setupHeaders(
    config: InternalAxiosRequestConfig
  ): Promise<InternalAxiosRequestConfig['headers']> {
    // Protect Request
    const { idToken } = await this.authService.getTokens();
    if (idToken) {
      config.headers.set('Authorization', `Bearer ${idToken}`);
    } else {
      // TODO: or, to refresh token: await this.authService.refreshToken();
      // TODO: or, to redirect to login page: History.push(paths.auth.login.to(), { replace: true });
      // TODO: or, to implement api key: config.headers['X-Api-Key'] = API_KEY;
    }

    // Localize Request
    const language = LocalStorage.getItem(StorageKeysType.I18n);
    if (language) {
      config.headers['Accept-Language'] = language;
    }

    // Uploading Files using FormData
    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    }

    return config.headers;
  }

  private setupInterceptors() {
    this.instance.interceptors.request.use(
      async (config) => {
        // TODO: implement an NProgress

        config.headers = await this.setupHeaders(config);

        return config;
      },
      (error: AxiosError) => Promise.reject(error)
    );

    this.instance.interceptors.response.use((response) => response, this.handleError);
  }

  private async handleError(error: AxiosError) {
    const { response } = error;
    this.loggerService.error(error);

    let errorMessage =
      (error?.response?.data as ResponseType)?.message ?? error?.message ?? 'Something went wrong!';

    if (errorMessage.toString() === '[object Object]') {
      errorMessage = 'Something went wrong!';
    }

    if (axios.isCancel(error)) {
      console.error('Request canceled', error.message);
      return Promise.reject(error);
    }

    switch (response?.status) {
      case 401:
        await this.authService.signOut({ options: { emitEvent: true } });
        break;
      case 403:
        History.push(paths.page403, { replace: true });
        break;
      case 404:
        History.push(paths.page404, { replace: true });
        break;
      default:
        break;
    }

    this.notificationService.show(errorMessage, { variant: 'error' });
    return Promise.reject(error);
  }

  async request<Res = any, Payload = any, Params = any>({
    url,
    method,
    showNotification = method !== 'GET' && featAppNotificationForAllWriteRequests.isEnabled,
    ...args
  }: RequestType<Payload, Params>): Promise<Res> {
    const response = await this.instance.request<ResponseType<Res>>({ url, method, ...args });
    const { status, data, message } = response.data;

    if (showNotification) {
      this.notificationService.show(message, {
        variant: status === 'success' ? 'success' : 'error',
      });
    }

    return data;
  }
}
