import Map from 'ol/Map';

import ModeManager from 'src/services/canvas/canvas-tools/mode-manager';
import { AmplitudeEvents, AmplitudeService } from 'src/services/amplitude/';
import { featCanvasThreads, featCanvasQuickActionsMenu } from 'src/configs/feature-flags';
import {
  Brush,
  DrawType,
  SelectType,
  AnnotationMode,
  ThreadCommentType,
} from 'src/services/canvas/types/types-canvas';

import MapService from './map-service';

// ----------------------------------------------------------------------

export type ShortcutsKeys = 's' | 'p' | 'b' | 'd' | 'r' | 'm' | 'q' | 'c' | 'n' | 'e';

const initialEnabledShortcuts: { [k in ShortcutsKeys]: boolean } = {
  s: true,
  p: true,
  b: true,
  d: true,
  r: true,
  n: false,
  m: true,
  q: true,
  e: featCanvasQuickActionsMenu.isEnabled,
  c: featCanvasThreads.isEnabled,
};

export default class MapShortcutsManager {
  private shortcuts: { [k in ShortcutsKeys]: AnnotationMode } = {
    s: SelectType.Single,
    p: SelectType.Polygon,
    m: SelectType.Translate,
    d: DrawType.Polygon,
    r: DrawType.Rectangle,
    n: Brush.BrushDraw,
    b: Brush.Brush,
    c: ThreadCommentType.Comment,
    e: null,
    q: null,
  };

  private enabledShortcuts = { ...initialEnabledShortcuts };

  private oldMode: AnnotationMode = null;

  private grabbingKeyPressed: boolean = false;

  constructor(private map: Map) {
    this.addListeners();
  }

  addListeners() {
    document.addEventListener('keydown', this.keyDownHandler.bind(this));
    document.addEventListener('keyup', this.keyUpHandler.bind(this));
  }

  removeListeners() {
    document.removeEventListener('keydown', this.keyDownHandler.bind(this));
    document.removeEventListener('keyup', this.keyUpHandler.bind(this));
  }

  updateEnabledShortcuts(enabledShortcuts: Partial<{ [k in ShortcutsKeys]: boolean }>) {
    this.enabledShortcuts = {
      ...this.enabledShortcuts,
      ...enabledShortcuts,
    };
  }

  disableAllShortcuts() {
    this.enabledShortcuts = {
      s: false,
      p: false,
      b: false,
      d: false,
      r: false,
      n: false,
      m: false,
      q: false,
      e: false,
      c: false,
    };
  }

  resetEnabledShortcuts() {
    this.enabledShortcuts = { ...initialEnabledShortcuts };
  }

  private static getShortcutString(event: KeyboardEvent): string {
    const key = `${event.key}`.toLowerCase();

    const pressedKeys = [];
    if (event.ctrlKey) pressedKeys.push('Ctrl');
    if (event.shiftKey) pressedKeys.push('Shift');
    if (event.altKey) pressedKeys.push('Alt');
    if (event.metaKey) pressedKeys.push('Meta');
    if (event.code === 'Space') pressedKeys.push('Space');
    if (!['alt', 'control', 'shift', 'meta', ' '].includes(key)) {
      pressedKeys.push(key);
    }
    return pressedKeys.join(' + ');
  }

  keyDownHandler(event: KeyboardEvent) {
    const key = `${event.key}`.toLowerCase() as ShortcutsKeys;
    const metaPressed = event.ctrlKey || event.metaKey;
    const grabbingKeyPressed = event.code === 'Space';

    const modeManager = ModeManager.getInstance();
    const currentMode = modeManager.getMode();
    const grabbingModeEnabled =
      grabbingKeyPressed && !this.grabbingKeyPressed && currentMode !== ThreadCommentType.Comment;
    const quickActionKeyPressed = event.code === 'KeyE' && this.enabledShortcuts.e;
    const shortcut = MapShortcutsManager.getShortcutString(event);
    let shortcutUsed = false;

    if (grabbingModeEnabled) {
      this.grabbingKeyPressed = true;
      this.oldMode = currentMode;
      modeManager.setMode(SelectType.Single, shortcut);
      this.updateCursor(true);
      shortcutUsed = true;
    } else if (quickActionKeyPressed) {
      MapService.getInstance().triggerQuickActionsMenu();
    } else if (
      key &&
      this.enabledShortcuts?.[key] &&
      key === ('c' as any) &&
      currentMode !== ThreadCommentType.Comment &&
      !metaPressed
    ) {
      modeManager.setMode(this.shortcuts[key], shortcut);
      shortcutUsed = true;
    } else if (
      key &&
      this.enabledShortcuts?.[key] &&
      currentMode === ThreadCommentType.Comment &&
      !metaPressed
    ) {
      //
    } else if (key && this.enabledShortcuts?.[key] && !metaPressed) {
      modeManager.setMode(this.shortcuts[key], shortcut);
      shortcutUsed = true;
    }

    if (shortcutUsed) {
      AmplitudeService.track(AmplitudeEvents.CanvasShortcutPressed, {
        'shortcut-key': shortcut,
        'shortcut-action': this.shortcuts[key] ?? 'unknown',
        'selected-tool': currentMode,
      });
    }
  }

  keyUpHandler(event: KeyboardEvent) {
    const modeManager = ModeManager.getInstance();
    const grabbingKeyPressed = event.code === 'Space';

    if (grabbingKeyPressed && this.grabbingKeyPressed) {
      this.grabbingKeyPressed = false;
      if (this.oldMode) modeManager.setMode(this.oldMode);
      this.oldMode = null;
      this.updateCursor(false);
    }
  }

  updateCursor(isGrabbing: boolean) {
    const el = this.map.getTargetElement();

    if (el) {
      el.style.cursor = isGrabbing ? 'grabbing' : '';
    }
  }
}
